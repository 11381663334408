import React from 'react';

import svg1 from '../images/1_create.svg';
import svg2 from '../images/2_register.svg';
import svg3 from '../images/3_receive.svg';

const Steps = () => {
  return (
    <div className="container mt-4">
      <hr className="my-4" />

      <div className="row align-items-center">
        <div className="col-md-7">
          <h2>1. Create your alias.</h2>
          <p className="lead">
            Go to lastmx.com and enter your email address. You will be given a
            unique alias in the form <em>something@lastmx.com</em>.
          </p>
        </div>
        <div className="col-md-5">
          <img src={svg1} className="img-fluid p-4" alt="" />
        </div>
      </div>

      <hr className="my-4" />

      <div className="row align-items-center">
        <div className="col-md-7 order-md-2">
          <h2>2. Use it on any website.</h2>
          <p className="lead">
            Register on the website you are visiting using your @lastmx.com
            address.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img src={svg2} className="img-fluid p-4" alt="" />
        </div>
      </div>

      <hr className="my-4" />

      <div className="row align-items-center">
        <div className="col-md-7">
          <h2>3. Receive mail as usual.</h2>
          <p className="lead">
            You will immediately receive any email the website sends you,
            without them having your address. If something goes wrong, you can
            cancel the address at anytime.
          </p>
        </div>
        <div className="col-md-5">
          <img src={svg3} className="img-fluid p-4" alt="" />
        </div>
      </div>

      <hr className="my-4" />
    </div>
  );
};

export default Steps;
