import React from 'react';
import { LogOnMount } from '@amplitude/react-amplitude';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Create from '../components/create';
import Features from '../components/features';
import Steps from '../components/steps';
import Pricing from '../components/pricing';

const IndexPage = () => (
  <Layout nav>
    <SEO title="Home" />
    <LogOnMount eventType="view homepage" />
    <Create />
    <Features />
    <Steps />
    <Pricing />
  </Layout>
);

export default IndexPage;
