import React from 'react';
import { Link } from 'gatsby';

const Pricing = () => {
  return (
    <div className="container" id="pricing">
      <h2 className="sr-only">Pricing</h2>
      <div className="card-deck mb-3 text-center">
        <div className="card mb-4 shadow-sm">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Starter</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title mb-5 pb-3">Free</h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited addresses</li>
              <li>Manual alias creation</li>
              <li>Receive &amp; reply</li>
              <li>@lastmx.com domain</li>
              <li>Community support</li>
            </ul>
            <a className="btn btn-lg btn-block btn-primary" href="#create">
              Try Now
            </a>
          </div>
        </div>
        <div className="card mb-4 shadow-sm">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Pro</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">
              $4.00 <small className="text-muted">/ mo</small>
            </h1>
            <h2 className="card-title pricing-card-title">
              €3.50 <small className="text-muted">/ mo</small>
            </h2>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited addresses</li>
              <li>Automatic alias creation</li>
              <li>Compose new messages</li>
              <li>Use your own domain</li>
              <li>Dedicated email support</li>
            </ul>
            <Link className="btn btn-lg btn-block btn-primary" to="/pro">
              Subscribe
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
