import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BounceLoader from 'react-spinners/BounceLoader';
import { Amplitude } from '@amplitude/react-amplitude';
import jsonpAdapter from 'axios-jsonp';

import styles from './create.module.scss';

const Create = () => {
  const [result, setResult] = useState(null);
  const [copied, setCopied] = useState(false);

  const handleReset = () => {
    setResult(null);
  };

  const handledCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSubmit = (values, actions) => {
    axios
      .post(`${process.env.API_ENDPOINT}forwardconfig`, {
        forwards: [values.email],
      })
      .then((r) => {
        actions.setSubmitting(false);
        setResult(r.data);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        actions.setErrors(mapError(error.response.data));
      });

    if (values.optin) {
      axios.get(process.env.MAILCHIMP_URI, {
        adapter: jsonpAdapter,
        callbackParamName: 'c',
        params: {
          EMAIL: values.email,
        },
      });
    }
  };

  const mapError = (e) => ({
    email: e.forwards && e.forwards[0] && e.forwards[0][0].join(','),
  });

  return (
    <Amplitude>
      {({ instrument }) => (
        <div className={`${styles.container} d-flex flex-fill`}>
          <div
            className="container text-white d-flex flex-row align-items-center justify-content-center"
            id="create"
          >
            <Formik
              initialValues={{ email: '', optin: false }}
              onSubmit={instrument('create alias', handleSubmit)}
              render={({ errors, status, touched, isSubmitting, values }) => (
                <>
                  {result && !isSubmitting && (
                    <div className="col-md-9 text-center">
                      <h1 className="mb-5">
                        Here is your private email address:
                      </h1>
                      <div className="form-row align-items-center justify-content-center">
                        <div className="col-md-8">
                          <label className="sr-only" htmlFor="email">
                            Your alias
                          </label>
                          <div className="input-group input-group-lg mb-3">
                            <input
                              className="form-control"
                              type="text"
                              readOnly
                              value={result ? result.alias : ''}
                            />
                            <div className="input-group-append">
                              <CopyToClipboard
                                text={result && result.alias}
                                onCopy={instrument('copy alias', handledCopied)}
                              >
                                <button
                                  className="btn btn-primary text-white text-uppercase"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {copied ? 'Copied!' : 'Copy'}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>
                          {status && status.msg && <div>{status.msg}</div>}
                        </div>
                      </div>
                      <div className="my-5">
                        This address will redirect to {values.email}.
                        <br />
                        Cancel it any time by clicking on the link in forwarded
                        emails.
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-primary"
                          onClick={handleReset}
                        >
                          Create a new address
                        </button>
                      </div>
                    </div>
                  )}
                  {isSubmitting && (
                    <div className={styles.loading}>
                      <BounceLoader size={20} color={'#fff'} />
                    </div>
                  )}
                  {!result && !isSubmitting && (
                    <div className="col-md-9 text-center">
                      <h1 className="mb-5">
                        LastMX protects your privacy with simple and secure mail
                        aliases
                      </h1>
                      <div className="mb-5">
                        Enter your email address below to generate a @lastmx.com
                        address pointing to it.
                      </div>
                      <Form>
                        <div className="form-row align-items-center justify-content-center">
                          <div className="col-md-8">
                            <label className="sr-only" htmlFor="email">
                              Your email
                            </label>
                            <div className="input-group input-group-lg mb-3">
                              <Field
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                autoFocus
                                placeholder="your@email.com"
                                required
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-primary text-white text-uppercase"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="form-check text-left">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="optin"
                                id="optin"
                                checked={values.optin}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="optin"
                              >
                                Keep me updated about LastMX news (optional)
                              </label>
                            </div>
                            {errors.email && touched.email && (
                              <div className={styles.error}>{errors.email}</div>
                            )}
                            {status && status.msg && <div>{status.msg}</div>}
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      )}
    </Amplitude>
  );
};

export default Create;
