import React from 'react';
import Octicon, { Beaker, Key, Package } from '@primer/octicons-react';

const Features = () => {
  return (
    <div className="container mt-4" id="features">
      <div className="row">
        <div className="col-lg-4">
          <div className="text-center mb-4">
            <Octicon icon={Beaker} size="large" />
            <h2>Simple</h2>
          </div>
          <p>
            You do not have to change any setting on your side to receive or
            send messages.
          </p>
        </div>
        <div className="col-lg-4">
          <div className="text-center mb-4">
            <Octicon icon={Key} size="large" />
            <h2>Secure</h2>
          </div>
          <p>
            All outgoing emails will be rewritten so that your private address
            is never revealed.
          </p>
        </div>
        <div className="col-lg-4">
          <div className="text-center mb-4">
            <Octicon icon={Package} size="large" />
            <h2>Try for free</h2>
          </div>
          <p>Try the service for free with a @lastmx.com address.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
